@import "~bulma/sass/utilities/initial-variables.sass";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&Open+Sans:wght@300;400;500;600;700&Inter:wght@100;300;400;500;600;700&display=swap');

$family-primary: 'Roboto', sans-serif;
$warning: #FFC73F;

@import '~bulma';
@import "~antd/dist/antd.min.css";

#root {
  height: 100vh !important; }

.ib-community-frame {
  overflow: hidden; }

.is-fullheight {
  height: 100vh !important; }

.isheightmax {
  min-height: 600px !important; }

.ib-widget-main {
  padding: 10px 0 0 0;
  margin-top: 1rem;
  box-sizing: border-box;
  border-radius: 4px; }

.ib-widget-container-main {
  padding: 0 30px;
  margin: 20px 0;
  max-width: 658px !important; }

.ib-sec {
  padding-top: 2.5rem; }

.theme-bg {
 }  //background-color: #EEE

.head-block {
  max-width: 572px !important; }

.ib-form-head {
  font-size: 1.8em;
  font-style: normal;
  line-height: 55px;
  letter-spacing: 0;
  color: #000000;
  display: block;
  margin: 0.8rem 1em 0.4rem 0.4rem;
  margin-inline: 0;
  font-weight: bold; }

.ib-form-subhead {
  font-style: normal;
  letter-spacing: 0;
  display: block;
  margin-block: 1em;
  margin-inline: 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #7B7C81; }

.ib-form-thanks {
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  color: #000000;
  display: block;
  margin-block: 1em;
  margin-inline: 0; }

.ib-input {
  border: 1px solid black;
  padding: 11px;
  font-size: 17px;
  margin: 14px 12px;
  border-radius: 4px; }

//.ib-form-input-comp:not(:last-child)
//  margin-bottom: 0 !important
.ib-form-instagram-input-comp {
  @extend .ib-form-input-comp;
  background: linear-gradient(180deg, #FF00FF 0%, #F29657 51.56%, #FF6161 100%) !important; }

.fa-ig-ib {
  color: #FFFFFF;
  vertical-align: middle;
  padding-left: 12px;
  order: 1; }

.ib-instagram-email-input {
  flex-grow: 2;
  border: 0;
  order: 2;
  background-color: white !important; }

.ib-instagram-username-input {
  flex-grow: 2;
  border: 0;
  order: 2;
  background-color: white !important; }

.ib-form-area-comp {
  background: #333;
  border-radius: 7px;
  align-items: center;
  display: flex !important;
  flex-direction: row;
  margin-bottom: 14px !important;
  //max-width: 572px !important
  min-width: 100%; }

.ib-address-input {
  flex-grow: 2;
  border: 0;
  order: 2;
  background-color: white !important;
  margin: 4px 8px !important; }

.ib-form-submit-button {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0;
  color: #ffffff;
  padding: 24px 18px;
  background: #111A22;
  border-radius: 63px;
  border-left: 0;
  margin-left: 0;
  min-height: 100%;
  order: 3;
  align-self: stretch;
  white-space: nowrap !important;
  &:hover {
    cursor: pointer;
    box-shadow: 6px 6px 6px 6px #7B7C81; } }

.fa {
  padding-right: 12px !important;
  max-width: 44px !important; }
.fa.fa-p1 {
  padding-right: 4px !important;
  max-width: 44px !important; }

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 6px !important;
  width: 80%;
  max-width: 400px; }

.modal_heading {
  font-style: normal;
  font-weight: 700;
  font-size: 32px; }

.join-head {
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  color: #111A22;
  background: #FFFFFF;
  border-radius: 6px 6px 0px 0px; }

.ibBox {
  background-color: #fff;
  border-radius: 6px;
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
  box-shadow: 0px 13.3166px 11.0971px rgba(76, 73, 129, 0.14); }

.dark {
  background-color: #333 !important;
  color: #fff !important;
  font-style: normal;
  font-weight: 400;
  box-shadow: 0px 13.3166px 11.0971px rgba(76, 73, 129, 0.14);
  border-radius: 6px; }

.ct1 {
 }  //height: 100vh !important

$card-transition-time: 0.5s;

.ib-card-parent {
  transition: transfor 1s;
  transform-style: preserve-3d;
  .ib-community-card.switched {
    animation: rotate-inverse $card-transition-time linear both; } }

.ib-community-card {
  box-shadow: 0px 25.7437px 34.325px rgba(41, 41, 41, 0.25);
  border-radius: 25.7437px;
  perspective: 500px;
  transform-style: preserve-3d;
  animation: rotate-inverse $card-transition-time linear both; }

.ib-card-content {}

@keyframes rotate {
  0% {
    transform: rotateY(0); }
  70% {
    transform: rotateY(200deg); }
  100% {
    transform: rotateY(180deg); } }
@keyframes rotate-inverse {
  0% {
    transform: rotateY(180deg); }
  70% {
    transform: rotateY(-20deg); }
  100% {
    transform: rotateY(0); } }
@keyframes fadeinto {
  0% {
    opacity: 0; }
  49% {
    opacity: 0.5; }
  99% {
    opacity: 1; } }

.ib-input {
  border: 1px solid black;
  padding: 11px;
  font-size: 17px;
  margin: 14px 12px;
  border-radius: 4px;
  overflow: hidden !important; }

.ib-form-input-comp {
  background: black;
  border-radius: 7px;
  align-items: center;
  display: flex !important;
  flex-direction: row;
  max-height: 58px;
  margin-bottom: 14px !important; }
.ib-form-instagram-input-comp {
  @extend .ib-form-input-comp;
  background: linear-gradient(180deg, #FF00FF 0%, #F29657 51.56%, #FF6161 100%) !important; }

.ib-form-email-comp {
  background: #111A22;
  border-radius: 7px;
  align-items: center;
  display: flex !important;
  flex-direction: row;
  max-height: 58px;
  margin-bottom: 14px !important;
 }  //max-width: 572px !important

.ib-instagram-email-input {
  flex-grow: 2;
  border: 0;
  order: 2;
  background-color: white !important; }

.ib-instagram-username-input {
  flex-grow: 2;
  border: 0;
  order: 2;
  background-color: white !important; }

.ib-form-span {
  font-style: normal;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 19px;
  display: flex;
  color: #7B7C81; }
.ib-brand-form-input {
  border-width: 3px !important;
  border-style: solid;
  border-radius: 7px; }
.ib-brand-form-instagram {
  @extend .ib-brand-form-input;
  border-color: #FF00FF !important; }
.ib-brand-form-email {
  @extend .ib-brand-form-input;
  border-color: #111A22 !important; }

.ib-community-image {
  border-radius: 7px !important;
  max-height: 600px !important; }

.ib-trivia-option {
  mix-blend-mode: normal;
  border: 2px solid #B9B9B9 !important;
  border-radius: 64px;
  cursor: pointer !important; }
.ib-trivia-option.correct {
  background-color: #C3FFE6 !important; }
.ib-trivia-option.wrong {
  background-color: #FFDDDD; }

.ib-trivia-option-pretext {
  background: #EFEFEF;
  mix-blend-mode: normal;
  display: inline !important;
  border-radius: 64px !important; }

.ib-trivia-option-text {
  display: inline !important; }

.ib-reward-box {
  background-color: #F4F6F7 !important; }
.ib-reward-box.copied {
  background-color: #F4F6F7 !important; }

#custom-countdown {
  width: 465px;
  height: 112px;
  text-align: center;
  background: #222;
  background-image: -webkit-linear-gradient(top, #222, #333, #333, #222);
  background-image: -moz-linear-gradient(top, #222, #333, #333, #222);
  background-image: -ms-linear-gradient(top, #222, #333, #333, #222);
  background-image: -o-linear-gradient(top, #222, #333, #333, #222);
  border: 1px solid #111;
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
  margin: auto;
  padding: 24px 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &:before {
    content: "";
    width: 8px;
    height: 65px;
    background: #444;
    background-image: -webkit-linear-gradient(top, #555, #444, #444, #555);
    background-image: -moz-linear-gradient(top, #555, #444, #444, #555);
    background-image: -ms-linear-gradient(top, #555, #444, #444, #555);
    background-image: -o-linear-gradient(top, #555, #444, #444, #555);
    border: 1px solid #111;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    display: block;
    position: absolute;
    top: 48px;
    left: -10px; }

  &:after {
    content: "";
    width: 8px;
    height: 65px;
    background: #444;
    background-image: -webkit-linear-gradient(top, #555, #444, #444, #555);
    background-image: -moz-linear-gradient(top, #555, #444, #444, #555);
    background-image: -ms-linear-gradient(top, #555, #444, #444, #555);
    background-image: -o-linear-gradient(top, #555, #444, #444, #555);
    border: 1px solid #111;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    display: block;
    position: absolute;
    top: 48px;
    right: -10px; }

  #custom-tiles {
    position: relative;
    z-index: 1;

    > span {
      width: 92px;
      max-width: 92px;
      font: bold 48px 'Droid Sans', Arial, sans-serif;
      text-align: center;
      color: #111;
      background-color: #ddd;
      background-image: -webkit-linear-gradient(top, #bbb, #eee);
      background-image: -moz-linear-gradient(top, #bbb, #eee);
      background-image: -ms-linear-gradient(top, #bbb, #eee);
      background-image: -o-linear-gradient(top, #bbb, #eee);
      border-top: 1px solid #fff;
      border-radius: 3px;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.7);
      margin: 0 7px;
      padding: 18px 0;
      display: inline-block;
      position: relative;
      &:before {
        content: "";
        width: 100%;
        height: 13px;
        background: #111;
        display: block;
        padding: 0 3px;
        position: absolute;
        top: 41%;
        left: -3px;
        z-index: -1; }
      &:after {
        width: 100%;
        height: 1px;
        background: #eee;
        border-top: 1px solid #333;
        display: block;
        position: absolute;
        top: 48%;
        left: 0; } } } }

$boxSize: 90px;
$circleSize: 75%;

.light-box {
  max-height: $boxSize !important;
  min-height: $boxSize !important;
  max-width: $boxSize !important;
  min-width: $boxSize !important;
  background-color: #DDDDDD !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px; }
.light-circle {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: $circleSize !important;
  height: $circleSize !important;
  border-radius: 50% !important;
  overflow: hidden;
  position: relative; }
.light-circle.dead {
  .line1 {
    width: calc(1.414 * 200px);
    transform: rotate(45deg);
    transform-origin: top left;
    border-top: 2px solid #000000;
    position: absolute;
    top: -2px;
    left: -1px;
    box-sizing: border-box; }
  .line2 {
    width: calc(1.414 * 200px);
    transform: rotate(-45deg);
    transform-origin: bottom left;
    border-top: 2px solid #000000;
    position: absolute;
    bottom: -2px;
    left: -1px;
    box-sizing: border-box; } }

.vertical-divider {
  position: absolute;
  display: table;
  text-align: center;
  height: 80%;
  width: 100%;
  .center-element {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    &:before, &:after {
      position: absolute;
      content: "";
      width: 1px;
      left: 50%;
      border-left: 1px solid rgba(0, 0, 0, 0.3); }
    &:before {
      bottom: 50%;
      top: 0;
      margin-bottom: 20px; }
    &:after {
      top: 50%;
      bottom: 0;
      margin-top: 20px; } } }


@media screen and (max-device-width: 768px) {
  .ib-form-submit-button {
    font-size: 17px !important;
    padding: 20px 32px 20px 32px !important;
    margin-top: 12px !important;
    margin-bottom: 8px !important; }
  .ib-instagram-username-input {
    padding-right: 1px !important; }
  .ib-main-form {
    margin-top: 0.8rem !important;
    padding-left: 0; }
  .ib-sec {
    padding-top: 0rem !important;
    margin-top: 0; }
  .ib-form-head {
    margin-top: 0.2rem !important;
    margin-bottom: 0 !important;
    line-height: 30px !important; }
  .ib-form-subhead {
    margin-top: 0.2rem !important;
    margin-bottom: 0.3rem !important; }
  .ib-widget-main {
    margin-top: 0 !important;
    padding: 0 !important; }
  .ib-input {
    padding: 9px 5px !important;
    margin: 10px 8px; }
  .ib-widget-container-main {
    padding: 0 4px !important;
    margin-top: 12px !important; }
  .col-card {
    border-radius: 0 !important;
    align-items: flex-start !important; }
  .ct1 {
    height: 100% !important; }
  .hero-body {
    padding: 2rem 0.5rem !important; }
  .card-content {
    padding: 1.5rem 0.75rem !important; }
  .light-box {
    $boxSize: 45px;
    max-height: $boxSize !important;
    min-height: $boxSize !important;
    max-width: $boxSize !important;
    min-width: $boxSize !important; }
  .light-circle {
    width: $circleSize !important;
    height: $circleSize !important;
    border-radius: 50% !important; } }

@media only screen and (min-device-width: 769px) {
  .ct1 {
    height: 100vh !important; } }
